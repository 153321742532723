import { insertCss } from "insert-css";
import { connectToChild } from "penpal";
import whenDomReady from "when-dom-ready";

import type { CsvData, onRecordEditType, onRecordsInitialType, ThemeType } from "./types";

// eslint-disable-next-line dot-notation
const MOUNT_URL = process.env["MOUNT_URL"] || "http://localhost:3000/importer";

const insertIframe = (id: string, importerDisplay: "inline" | "modal") => {
  const usecsvInlineWrapper = document.getElementById("usecsv-importer-inline-wrapper");

  const usecsvContainerStyles =
    importerDisplay === "modal" || !usecsvInlineWrapper
      ? `.usecsv_container { position: fixed; top: 0px; bottom: 0; right: 0; left: 0; z-index: 100000; }`
      : ``;

  insertCss(`
  ${usecsvContainerStyles}
  .usecsv_container iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    border-width: 0;
  }
  .usecsv_container {
    overflow: hidden;
    overscroll-behavior-x: none;
  }
`);

  document.body.insertAdjacentHTML("beforeend", `<div id=${id} class="usecsv_container loading"></div>`);
  const iframe = document.createElement("iframe");
  iframe.setAttribute("src", MOUNT_URL);
  const usecsvContainer = document.getElementById(id);
  usecsvContainer?.appendChild(iframe);
  if (importerDisplay === "inline" && usecsvInlineWrapper) {
    // usecsvInlineWrapper > usecsvContainer > iframe
    usecsvInlineWrapper?.appendChild(usecsvContainer as HTMLElement);
  }

  return iframe;
};

const useCsvPlugin = ({
  importerKey,
  user,
  metadata,
  onData,
  onRecordsInitial,
  onRecordEdit,
  importerDisplay = "modal",
  onClose,
  theme,
  batchSize,
}: {
  importerKey: string;
  user?: Record<string, string | number>;
  metadata?: Record<string, string | number>;
  onData?: (
    data: CsvData,
    close: () => void,
  ) => void | Promise<{
    errors?: {
      row: Record<string, string | number | undefined>;
      error: string;
    }[];
  } | void>;
  onRecordsInitial?: onRecordsInitialType;
  onRecordEdit?: onRecordEditType;
  importerDisplay?: "modal" | "inline";
  onClose?: () => void;
  theme?: ThemeType;
  batchSize?: number;
}) => {
  const id = `usecsv-${Math.round(Math.random() * 100000000)}`;
  return whenDomReady().then(() => {
    const iframe = insertIframe(id, importerDisplay);
    const closeIframe = () => {
      document.getElementById(id)?.remove();
    };
    const iframeConnection = connectToChild({
      iframe,
      methods: {
        closeIframe,
        ...(onData
          ? {
              onData: (data: CsvData) => onData(data, closeIframe),
            }
          : {}),
        ...(onRecordsInitial
          ? {
              onRecordsInitial,
            }
          : {}),
        ...(onRecordEdit
          ? {
              onRecordEdit,
            }
          : {}),
        ...(onClose
          ? {
              onClose,
            }
          : {}),
      },
    });
    iframeConnection.promise.then((child: any) => {
      document.getElementById(id)?.classList.remove("loading");
      // eslint-disable-next-line dot-notation
      child["setParams"] && child["setParams"]({ importerKey, user, metadata, importerDisplay, theme, batchSize });
    });
    return iframeConnection;
  });
};

export default useCsvPlugin;
